<template>
    <div class="tw-w-full">
        <div
            v-for="(subBlock, subBlockName) in dataHeader"
            :key="subBlockName"
            class="tw-mb-8 tw-relative"
        >
            <div class="tw-mb-2 tw-w-full subblock-name">
                <span
                    class="tw-whitespace-no-wrap tw-text-lg tw-font-semibold text-blue  subblock-name__text"
                    >{{ subBlockName }}</span
                >
            </div>
            <div
                class="tw-px-3 tw-pb-3 tw-pt-3 tw-border tw-border-gray-200 tw-rounded"
            >
                <div class="tw-flex tw-w-mx-2">
                    <div class="tw-w-1/2 tw-px-2">
                        <div class="tw-w-full" v-if="subBlock.L">
                            <div
                                class="tw-flex tw-mx-2"
                                v-for="(item, subBlock) in subBlock.L"
                                :key="subBlock"
                            >
                                <span
                                    class="tw-whitespace-no-wrap tw-w-3/6 tw-px-2 tw-text-gray-800 tw-font-semibold tw-leading-loose"
                                >
                                    {{ item.dispay_name }}
                                </span>
                                <span
                                    class="tw-w-3/6 tw-px-2 tw-text-gray-700 tw-leading-loose"
                                    >{{ item.field_value }}</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="tw-w-1/2 tw-px-2">
                        <div class="tw-w-full" v-if="subBlock.R">
                            <div
                                class="tw-flex tw-mx-2"
                                v-for="(item, subBlock) in subBlock.R"
                                :key="subBlock"
                            >
                                <span
                                    class="tw-whitespace-no-wrap tw-w-3/6 tw-px-2 tw-text-gray-800 tw-font-semibold tw-leading-loose"
                                >
                                    {{ item.dispay_name }}
                                </span>
                                <span
                                    class="tw-w-3/6 tw-px-2 tw-text-gray-700 tw-leading-loose"
                                    >{{ item.field_value }}</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['dataHeader']
}
</script>

<style lang="scss" scoped>
.subblock-name {
    .text {
        background-color: #fff;
        padding: 0 0;
        display: inline-block;
    }
}
</style>
