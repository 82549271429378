<template>
    <div class="tw-w-full">
        <div
            v-for="(subBlock, subBlockName) in dataBody"
            :key="subBlockName"
            class="tw-mb-10 tw-relative"
        >
            <div class="tw-mb-1 tw-w-full subblock-name">
                <span
                    class="tw-text-lg tw-font-semibold text-blue subblock-name__text"
                    >{{ subBlockName }}</span
                >
            </div>
            <div>
                <table class="table table-bordered table-hover ui-table">
                    <thead>
                        <th class="tw-text-center">DESCRIPTION</th>
                        <th class="tw-text-center" colspan="2">AMOUNT</th>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(item, subBlock) in subBlock"
                            :key="subBlock"
                        >
                            <td
                                class="tw-whitespace-no-wrap tw-text-gray-800 tw-font-semibold"
                            >
                                {{ item.dispay_name }}
                            </td>
                            <td
                                class="tw-whitespace-no-wrap tw-text-gray-700"
                                style="width: 107px; text-align: right;"
                            >
                                {{
                                    item.display_side === 'R'
                                        ? formatNumber(item.field_value)
                                        : ''
                                }}
                            </td>
                            <td
                                class="tw-whitespace-no-wrap tw-text-gray-700"
                                style="width: 107px; text-align: right;"
                            >
                                {{
                                    item.display_side === 'L'
                                        ? formatNumber(item.field_value)
                                        : ''
                                }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    props: ['dataBody'],
    computed: {
        ...mapGetters(['formatNumber'])
    },
    methods: {
        formatPrice (value) {
            let val = (value / 1).toFixed(2).replace(',', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
    }
}
</script>

<style lang="scss" scoped>
.subblock-name {
    &__text {
        padding: 0 0;
        display: inline-block;
    }
}
</style>
